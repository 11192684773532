import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../../tools/i18n';
import theme from '../../theme';
import ProductThumbnail from '../thumbnail';
import ItemPrice from '../partials/ItemPrice';
import BrandName from '../partials/BrandName';
import StockIndicator from '../partials/StockIndicator';
import PurchaseButton from '../partials/PurchaseButton';
import ProductLink from '../partials/ProductLink';
import { Close, PlaylistAdd } from '@material-ui/icons';
import useUserStore from '../../../services/stores/UserStore';
import CallToActionButton from '@components/products/partials/CallToActionButton';

const useStyles = makeStyles(() => ({
    card: {
        width: '100%',
        borderBottom: '1px solid #f0f0f0',
        marginBottom: 20
    },
    productCard: {
        display: 'grid',
        gridTemplateColumns: '1fr 200px',
        gap: '1rem',
        padding: '0.5rem',
        '& button': {
            marginTop: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },
    },
    productInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem',
        color: 'inherit',
        textDecoration: 'inherit',
    },
    item: {
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '80px 1fr',
        gap: '1rem',
        width: '100%',
    },
    item__image: {
        display: 'flex',
        position: 'relative',
        marginRight: 15,
        flexShrink: 0,
        width: '100%',
        height: 'fit-content',
        '.compact &': {
            width: 80,
        },
        [theme.breakpoints.down('sm')]: {
            width: 90,
        },
        '&:after': {
            content: '""',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            boxShadow: 'inset 0 0 90px 8px #f3f3f5',
            mixBlendMode: 'multiply',
            pointerEvents: 'none',
        },
    },
    remove: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: -8,
        top: -8,
        zIndex: 3,
        padding: '0px',
        background: 'rgb(234 239 242)',
        border: '4px solid white',
        color: '#333',
        borderRadius: '50%',
        height: 28,
        width: 28,
        cursor: 'pointer',
        transition: 'ease background 0.2s',
        '& > svg': {
            fill: '#333',
            fontSize: 14,
        },
        '&:hover': {
            background: 'white',
        },
    },
    buttonGroup: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        justifyContent: 'center',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: '104px',
        },
    },
}), { name: 'FavoriteItem' });

export default function FavoriteItem(
    {
        product,
        showBuyButton = true,
        classNames = [],
        style = null,
        styleCard = null,
        after = null,
        onRemove = null,
        buttons = null,
    },
) {
    const classes = useStyles();
    const { t } = useTranslation();
    const favoriteMethods = useUserStore((state) => state.favoriteMethods);
    const user = useUserStore();

    function renderRemoveFavoriteButton() {
        return <div className={classes.remove} onClick={() => {
            favoriteMethods.removeFromFavorites(product.id);
            onRemove ? onRemove(product.id) : null;
        }}>
            <Close/>
        </div>
    }

    function renderMoveToList() {
        if (!showBuyButton) {
            return null;
        }

        return <CallToActionButton
            size={'x-small'}
            colorScheme={'bg-white'}
            onClick={() => {
                user.toggleWishlistDrawer(product.id, true);
            }}
        >
            <PlaylistAdd fill={'#253138'}></PlaylistAdd>
        </CallToActionButton>
    }

    return <div className={classes.card} style={styleCard}>
        <article style={style} className={[ classes.item, ...classNames ].join(' ')}>
            <ProductLink product={product} className={classes.item__image}>
                <ProductThumbnail className={classes.item__image} size={550} image={product?.cover}></ProductThumbnail>
            </ProductLink>
            <section className={classes.productCard}>
                <ProductLink product={product} className={classes.productInfo}>
                    <BrandName name={product?.manufacturer?.translated?.name}></BrandName>
                    <div>{product?.translated?.name}</div>
                    <ItemPrice product={product}></ItemPrice>
                </ProductLink>
                {renderRemoveFavoriteButton()}
            </section>
        </article>
        <section className={classes.actions}>
            <StockIndicator
                show={showBuyButton}
                style={{ padding: '0.75rem 0.1rem' }}
                deliveryTime={product.deliveryTime}></StockIndicator>
            <div className={classes.buttonGroup}>
                {buttons}
                {renderMoveToList()}
                <PurchaseButton
                    product={product}
                    colorScheme={'bg-white'}
                    show={showBuyButton}
                    size={'x-small'}
                    icon
                ></PurchaseButton>
            </div>
            {after}
        </section>
    </div>;
}
